import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Responsive SVG drawing with React and useLayoutEffect",
  "description": "How do you connect grid or flexbox positioned elements with a curvy line? Here's a technique I have to re-learn every time. Writing it down for next time",
  "date": "2021-10-05T08:00:00.000Z",
  "published": "2021-10-05T08:00:00.000Z",
  "image": "./responsive-svg-drawing-with-react-and-uselayouteffect.png"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`How do you connect grid or flexbox positioned elements with a curvy line?`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.imgur.com/b4SAaVd.png",
        "alt": "SVG lines connecting complex interactive elements"
      }}></img></p>
    <p>{`Here's a technique I have to re-learn every time. Writing it down for next time 😅`}</p>
    <div><div parentName="div" {...{
        "className": "static-tweet-embed"
      }}>{`
        `}<a parentName="div" {...{
          "className": "author",
          "href": "https://t.co/VuU1lFnIe7"
        }}><img parentName="a" {...{
            "src": "https://pbs.twimg.com/profile_images/1423736293385662466/AnF0Fsi6_normal.jpg",
            "loading": "lazy",
            "alt": "Swizec Teller writing a secret book avatar"
          }}></img><b parentName="a">{`Swizec Teller writing a secret book`}</b>{`@Swizec`}</a>{`
        `}<blockquote parentName="div">{`getBoundingClientRect() is giving me nonsense co-ordinates and I can't figure out why`}<br parentName="blockquote"></br><br parentName="blockquote"></br>{`2 hours ago this was supposed to be easy `}</blockquote>{`
        `}<div parentName="div" {...{
          "className": "media"
        }}><img parentName="div" {...{
            "src": "https://pbs.twimg.com/tweet_video_thumb/FA0a_RoVQAAZ0hG.jpg",
            "width": "100%",
            "loading": "lazy",
            "alt": "Tweet media"
          }}></img></div>{`
        `}<div parentName="div" {...{
          "className": "time"
        }}><a parentName="div" {...{
            "href": "https://twitter.com/Swizec/status/1444840735266643973"
          }}>{`1:44:09 AM – 10/4/2021`}</a></div>{`
        `}<div parentName="div" {...{
          "className": "stats"
        }}><a parentName="div" {...{
            "href": "https://twitter.com/intent/like?tweet_id=1444840735266643973",
            "className": "like"
          }}><svg parentName="a" {...{
              "viewBox": "0 0 24 24",
              "className": "r-m0bqgq r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr",
              "style": {}
            }}><g parentName="svg"><path parentName="g" {...{
                  "d": "M12 21.638h-.014C9.403 21.59 1.95 14.856 1.95 8.478c0-3.064 2.525-5.754 5.403-5.754 2.29 0 3.83 1.58 4.646 2.73.814-1.148 2.354-2.73 4.645-2.73 2.88 0 5.404 2.69 5.404 5.755 0 6.376-7.454 13.11-10.037 13.157H12zM7.354 4.225c-2.08 0-3.903 1.988-3.903 4.255 0 5.74 7.034 11.596 8.55 11.658 1.518-.062 8.55-5.917 8.55-11.658 0-2.267-1.823-4.255-3.903-4.255-2.528 0-3.94 2.936-3.952 2.965-.23.562-1.156.562-1.387 0-.014-.03-1.425-2.965-3.954-2.965z"
                }}></path></g></svg>{`8`}</a>{` `}<a parentName="div" {...{
            "href": "https://twitter.com/Swizec/status/1444840735266643973",
            "className": "reply"
          }}><svg parentName="a" {...{
              "viewBox": "0 0 24 24",
              "className": "r-m0bqgq r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr"
            }}><g parentName="svg"><path parentName="g" {...{
                  "d": "M14.046 2.242l-4.148-.01h-.002c-4.374 0-7.8 3.427-7.8 7.802 0 4.098 3.186 7.206 7.465 7.37v3.828c0 .108.044.286.12.403.142.225.384.347.632.347.138 0 .277-.038.402-.118.264-.168 6.473-4.14 8.088-5.506 1.902-1.61 3.04-3.97 3.043-6.312v-.017c-.006-4.367-3.43-7.787-7.8-7.788zm3.787 12.972c-1.134.96-4.862 3.405-6.772 4.643V16.67c0-.414-.335-.75-.75-.75h-.396c-3.66 0-6.318-2.476-6.318-5.886 0-3.534 2.768-6.302 6.3-6.302l4.147.01h.002c3.532 0 6.3 2.766 6.302 6.296-.003 1.91-.942 3.844-2.514 5.176z"
                }}></path></g></svg>{`4`}</a></div>{`
    `}</div></div>
    <p>{`PS: if you're into this sort of thing, check out `}<a parentName="p" {...{
        "href": "https://reactfordataviz.com"
      }}>{`React for Dataviz`}</a>{` where I teach how to expertly wield React for playfully visual components`}</p>
    <h2 {...{
      "id": "the-problem"
    }}>{`The problem`}</h2>
    <p><img parentName="p" {...{
        "src": "https://i.imgur.com/joIM0ET.gif",
        "alt": "Button is hiding, line is delayed"
      }}></img></p>
    <p>{`You have complex interactive elements on the page. You don't know how big they are or how complex. Rendering with SVG is out of the question.`}</p>
    <p>{`You used flexbox or grid to neatly position them in a `}<inlineCode parentName="p">{`<div>`}</inlineCode>{`. Lets you leverage the browser's layouting abilities and renders perfectly on every screen.`}</p>
    <p>{`Like this:`}</p>
    <iframe {...{
      "src": "https://codesandbox.io/embed/peaceful-frog-bf5zc",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>
    <p>{`They're buttons in this example. Later we'll use that to make the example harder – dynamic elements 😅`}</p>
    <p>{`Now assume this is a data visualization of some sort. The elements are points on a graph, a tree data structure, or you need visual flourish to make it pretty.`}</p>
    <p>{`How do you draw complex lines between elements you can't control? SVG hates uncertainty. 🤔`}</p>
    <h2 {...{
      "id": "svg-drawing-synced-to-dom-elements"
    }}>{`SVG drawing synced to DOM elements`}</h2>
    <p>{`To draw an SVG line between elements you don't control, you'll need a couple ingredients:`}</p>
    <ol>
      <li parentName="ol">{`An SVG canvas that sits behind your elements`}</li>
      <li parentName="ol">{`DOM Refs to the elements`}</li>
      <li parentName="ol">{`Use `}<inlineCode parentName="li">{`getBoundingClientRect()`}</inlineCode>{` to measure size and position`}</li>
    </ol>
    <p><a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/API/Element/getBoundingClientRect"
      }}>{`getBoundingClientRect`}</a>{` measures the size and position of your element `}<em parentName="p">{`relative to the screen`}</em>{`. This part gets me every time.`}</p>
    <div><div parentName="div" {...{
        "className": "static-tweet-embed"
      }}>{`
        `}<a parentName="div" {...{
          "className": "author",
          "href": "https://t.co/VuU1lFnIe7"
        }}><img parentName="a" {...{
            "src": "https://pbs.twimg.com/profile_images/1423736293385662466/AnF0Fsi6_normal.jpg",
            "loading": "lazy",
            "alt": "Swizec Teller writing a secret book avatar"
          }}></img><b parentName="a">{`Swizec Teller writing a secret book`}</b>{`@Swizec`}</a>{`
        `}<blockquote parentName="div">{`You have to take the parent's getBoundingClientRect and adjust your coordinates by its position`}<br parentName="blockquote"></br><br parentName="blockquote"></br>{`I forget and re-learn every time 🤦‍♀️`}</blockquote>{`
        
        `}<div parentName="div" {...{
          "className": "time"
        }}><a parentName="div" {...{
            "href": "https://twitter.com/Swizec/status/1444882488099180545"
          }}>{`4:30:04 AM – 10/4/2021`}</a></div>{`
        `}<div parentName="div" {...{
          "className": "stats"
        }}><a parentName="div" {...{
            "href": "https://twitter.com/intent/like?tweet_id=1444882488099180545",
            "className": "like"
          }}><svg parentName="a" {...{
              "viewBox": "0 0 24 24",
              "className": "r-m0bqgq r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr",
              "style": {}
            }}><g parentName="svg"><path parentName="g" {...{
                  "d": "M12 21.638h-.014C9.403 21.59 1.95 14.856 1.95 8.478c0-3.064 2.525-5.754 5.403-5.754 2.29 0 3.83 1.58 4.646 2.73.814-1.148 2.354-2.73 4.645-2.73 2.88 0 5.404 2.69 5.404 5.755 0 6.376-7.454 13.11-10.037 13.157H12zM7.354 4.225c-2.08 0-3.903 1.988-3.903 4.255 0 5.74 7.034 11.596 8.55 11.658 1.518-.062 8.55-5.917 8.55-11.658 0-2.267-1.823-4.255-3.903-4.255-2.528 0-3.94 2.936-3.952 2.965-.23.562-1.156.562-1.387 0-.014-.03-1.425-2.965-3.954-2.965z"
                }}></path></g></svg>{`4`}</a>{` `}<a parentName="div" {...{
            "href": "https://twitter.com/Swizec/status/1444882488099180545",
            "className": "reply"
          }}><svg parentName="a" {...{
              "viewBox": "0 0 24 24",
              "className": "r-m0bqgq r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr"
            }}><g parentName="svg"><path parentName="g" {...{
                  "d": "M14.046 2.242l-4.148-.01h-.002c-4.374 0-7.8 3.427-7.8 7.802 0 4.098 3.186 7.206 7.465 7.37v3.828c0 .108.044.286.12.403.142.225.384.347.632.347.138 0 .277-.038.402-.118.264-.168 6.473-4.14 8.088-5.506 1.902-1.61 3.04-3.97 3.043-6.312v-.017c-.006-4.367-3.43-7.787-7.8-7.788zm3.787 12.972c-1.134.96-4.862 3.405-6.772 4.643V16.67c0-.414-.335-.75-.75-.75h-.396c-3.66 0-6.318-2.476-6.318-5.886 0-3.534 2.768-6.302 6.3-6.302l4.147.01h.002c3.532 0 6.3 2.766 6.302 6.296-.003 1.91-.942 3.844-2.514 5.176z"
                }}></path></g></svg>{`1`}</a></div>{`
    `}</div></div>
    <p>{`You have to subtract the SVG's `}<inlineCode parentName="p">{`getBoundingClientRect()`}</inlineCode>{` position to get coordinates relative to your canvas.`}</p>
    <h3 {...{
      "id": "curvyline-component"
    }}>{`CurvyLine component`}</h3>
    <p>{`A component that draws a line between 2 elements looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const CurvyLine = ({ sourceRef, targetRef, canvasRef }) => {
  const sourceRect = sourceRef?.current?.getBoundingClientRect()
  const targetRect = targetRef?.current?.getBoundingClientRect()
  const canvasRect = canvasRef?.current?.getBoundingClientRect()

  if (!sourceRect || !targetRect || !canvasRect) {
    // avoid render when refs undefined
    return null
  }

  // bezier curve generator
  const curve = d3.linkHorizontal()
  // calculate positions
  const source = [
    sourceRect.left + sourceRect.width / 2 - canvasRect.left,
    sourceRect.top + sourceRect.height / 2 - canvasRect.top,
  ]
  const target = [
    targetRect.left + targetRect.width / 2 - canvasRect.left,
    targetRect.top + targetRect.height / 2 - canvasRect.top,
  ]

  return <path d={curve({ source, target })} stroke="black" fill="none" />
}
`}</code></pre>
    <p>{`We draw the curve in 5 steps:`}</p>
    <ol>
      <li parentName="ol">{`Measure all the elements we need – source, target, and canvas`}</li>
      <li parentName="ol">{`Bail if the refs aren't ready. React doesn't have the DOM elements on its first rendering pass. This is important later, you'll see 💡`}</li>
      <li parentName="ol">{`Use `}<inlineCode parentName="li">{`d3.linkHorizontal`}</inlineCode>{` to generate the curve`}</li>
      <li parentName="ol">{`Prepare SVG coordinates for the curve – making sure to subtract the canvas coordinates`}</li>
      <li parentName="ol">{`Render a `}<inlineCode parentName="li">{`<path>`}</inlineCode>{` element`}</li>
    </ol>
    <h3 {...{
      "id": "rendering-the-curvyline"
    }}>{`Rendering the CurvyLine`}</h3>
    <p>{`We use an absolutely positioned SVG element to create a canvas that sits behind our elements.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div className="grid-2-cols">
  <!-- position: absolute takes it out of the grid -->
  <svg className="absolute-svg"></svg>
  <div className="column">
    <button>Button 1</button>
    <button>Button 2</button>
  </div>
  <div className="column">
    <button>Button 3</button>
    <button>Button 4</button>
  </div>
</div>
`}</code></pre>
    <p>{`We then add our `}<inlineCode parentName="p">{`<CurvyLine>`}</inlineCode>{` component and pass the refs it needs:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// prepare the refs
const button1Ref = useRef(null)
const button4Ref = useRef(null)
const canvasRef = useRef(null)

// ...

;<div className="grid-2-cols">
  <svg className="absolute-svg" ref={canvasRef}>
    <CurvyLine
      sourceRef={button1Ref}
      targetRef={button4Ref}
      canvasRef={canvasRef}
    />
  </svg>
  <div className="column">
    <button ref={button1Ref}>Button 1</button>
    <button>Button 2</button>
  </div>
  <div className="column">
    <button>Button 3</button>
    <button ref={button4Ref}>Button 4</button>
  </div>
</div>
`}</code></pre>
    <p><inlineCode parentName="p">{`useRef`}</inlineCode>{` creates a piece of mutable state and passing it to a DOM element as `}<inlineCode parentName="p">{`ref={}`}</inlineCode>{` creates a reference to that node. We can use that to call any DOM-level APIs – `}<inlineCode parentName="p">{`getBoundingClientRect`}</inlineCode>{` in our case.`}</p>
    <h3 {...{
      "id": "but-nothing-happens-"
    }}>{`But nothing happens 🤔`}</h3>
    <p>{`Stubbornly, our curvy line doesn't show up.`}</p>
    <iframe {...{
      "src": "https://codesandbox.io/embed/keen-tdd-k6u72",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>
    <p>{`That's because we're 1 render behind. CodeSandbox re-renders on code changes so you can make the line show up by poking the code.`}</p>
    <p>{`Another way to show you we're behind is to add a piece of state that re-renders when you click a button.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const [counter, setCounter] = useState(0)
function clicked() {
  setCounter(counter + 1)
}

// ...

;<button ref={button1Ref} onClick={clicked}>
  Click me
</button>
`}</code></pre>
    <p>{`Click the button and trigger a re-render. We don't even use the state anywhere!`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.imgur.com/ZjxJdt2.gif",
        "alt": "SVG rendering is 1 step behind"
      }}></img></p>
    <p>{`Try it yourself 👇`}</p>
    <iframe {...{
      "src": "https://codesandbox.io/embed/musing-mcclintock-02ndt",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>
    <p>{`Dynamic elements make the situation worse. Sometimes rendered, sometimes not.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.imgur.com/joIM0ET.gif",
        "alt": "Button is hiding, line is delayed"
      }}></img></p>
    <p>{`Look at that SVG curve staying 1 render behind!`}</p>
    <p>{`That's because React can't know the element a `}<inlineCode parentName="p">{`ref`}</inlineCode>{` is going to represent until a rendering pass is complete. And when the pass is complete, React doesn't go back to re-render your component that depends on a ref.`}</p>
    <p><inlineCode parentName="p">{`useEffect`}</inlineCode>{` is a common attempt to solve this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const ref = useRef(null)
useEffect(() => {
  if (ref.current) {
    // measure and save into state
  }
}, [ref])
`}</code></pre>
    <p>{`But that doesn't work. The effect runs too late and you may end up with stale measurements in your state.`}</p>
    <h2 {...{
      "id": "make-it-perfect-with-uselayouteffect"
    }}>{`Make it perfect with useLayoutEffect`}</h2>
    <p>{`The perfect solution I've found is to combine `}<inlineCode parentName="p">{`useLayoutEffect`}</inlineCode>{` and a dirty forced re-render. Dirty because it feels wrong.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const [forceRender, setForceRender] = useState(0)
useLayoutEffect(() => {
  setForceRender(forceRender + 1)
}, [ref])
`}</code></pre>
    <p>{`Like the button before, we force a re-render by updating state. Except now that happens when the `}<inlineCode parentName="p">{`ref`}</inlineCode>{` updates instead of when a user clicks something.`}</p>
    <p>{`And because it's a `}<inlineCode parentName="p">{`useLayoutEffect`}</inlineCode>{`, the update runs synchronously during initial render. You get zero delay:`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.imgur.com/lZ0OQNi.gif",
        "alt": "The curvy line draws beautifully"
      }}></img></p>
    <iframe {...{
      "src": "https://codesandbox.io/embed/distracted-easley-zmz6g",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>
    <p>{`We put the trick in `}<inlineCode parentName="p">{`<CurvyLine>`}</inlineCode>{` to make the component more self-contained and easier to use.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const CurvyLine = ({ sourceRef, targetRef, canvasRef }) => {
  const sourceRect = sourceRef?.current?.getBoundingClientRect();
  const targetRect = targetRef?.current?.getBoundingClientRect();
  const canvasRect = canvasRef?.current?.getBoundingClientRect();

    // force a 2nd-pass re-render when refs become available
  const [forceRender, setForceRender] = useState(0);
  useLayoutEffect(() => {
    setForceRender(forceRender + 1);
  }, [sourceRef, targetRef, canvasRef]);

  if (!sourceRect || !targetRect || !canvasRect) {
    // avoid render when refs undefined
    return null;
  }

  // ...
`}</code></pre>
    <p>{`React doesn't know this component changed when you un-render a button because refs are stable. You have to prompt it with a conditional rendering:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`{
  showButton1 && (
    <CurvyLine
      sourceRef={button1Ref}
      targetRef={button4Ref}
      canvasRef={canvasRef}
    />
  )
}
`}</code></pre>
    <p>{`Now you may think this is silly – why did we use `}<inlineCode parentName="p">{`useLayoutEffect`}</inlineCode>{`, if we're un-rendering the line anyway? Because without the effect you run into the 1-step-behind issue and never render the line.`}</p>
    <h2 {...{
      "id": "conclusion"
    }}>{`Conclusion`}</h2>
    <p>{`You can use refs and getBoundingClientRect to adjust your drawing to responsive layouts and dynamically positioned elements. Use a forced 2nd pass re-render to ensure it stays in sync.`}</p>
    <p>{`It's not stupid if it works ✌️`}</p>
    <p>{`Cheers,`}<br />{`
~Swizec`}</p>
    <p>{`PS: to make your SVG follow responsive layout changes live as you resize the screen (only designers and developers do that), you'll have to trigger a re-render when screen size changes`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      